<template>
  <v-app
    :id="'login' + loginCustom"
    :style="`background-image: linear-gradient(${colorPrimary}, ${colorPrimary}), url('${appLoginBackground}')`"
  >
    <v-main>
      <v-app-bar app color="primary" fixed dark clipped-left elevate-on-scroll>
        <router-link to="/" class="ml-2 mr-3 hidden-sm-and-down">
          <img
            v-if="!isDocline"
            :src="appLogoSecondary"
            :class="appMenuLogoClass"
            style="max-height: 32px"
            alt="logo"
          />
        </router-link>
      </v-app-bar>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md6 lg6 class="activate-account">
            <div class="text-center bg-gradient">
              <v-card class="elevation-1 pa-3 card-activate-account">
                <v-card-text>
                  <div class="text-center pa-5">
                    <v-progress-circular width="2" color="primary" indeterminate />
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import { acceptLinkPatientProfessionalUrl } from '@/config/config';

export default {
  data: () => ({
    loginCustom: '',
    colorPrimary: '#1976D2eb',
  }),

  computed: {
    ...mapState({
      appDisplayName: state => state.app.displayName,
      appLoginBackground: state => state.app.images.loginBackground,
      appLogoSecondary: state => state.app.images.logoSecondary,
      appMenuLogoClass: state => state.app.styles.menuLogoClass,
      appName: state => state.app.name,
    }),

    isDocline() {
      return /^docline/.test(this.appName);
    },
  },

  created() {
    this.colorPrimary = this.$vuetify.theme.primary + 'eb';

    this.$http
      .post(acceptLinkPatientProfessionalUrl, {
        token: this.$route.params.token,
      })
      .then(() => {
        this.$router.push('/login');
      });
  },
};
</script>

<style scoped lang="css">
.activate-account {
  max-width: 1080px;
  margin: 0 auto !important;
  float: none !important;
  padding: 20px;
}

.bg-gradient {
  background: var(--v-secondary-base);
  background: linear-gradient(114deg, var(--v-primary-base) 20%, var(--v-secondary-base) 100%);
  border-radius: 10px;
  padding: 1px 30px 30px 30px !important;
  max-width: 790px;
}

.card-activate-account {
  margin-top: -32px;
}
</style>
